.calendar-widget {
    margin-bottom: 30px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
    background: none !important;
    box-shadow: 0 0 0 2px #9372073d;
    color: #937207 !important;
}

.react-calendar__tile--active abbr {
    color: #937207 !important;
}

.react-calendar__month-view__days__day--weekend abbr {
    color: #937207 !important;
}

.react-calendar__navigation {
    display: flex;
}
